import React, { useState, useRef, useEffect, useCallback } from 'react';
import { User, LogOut, Settings } from 'lucide-react';

const Profiledropdown = ({
    authUser, 
    handleNavigation, 
    karwaisko,  
    language,
    ST,
    isAuthenticated
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleProfileClick = () => {
    const doctorId = authUser().doctorId;
    console.log(doctorId);
    handleNavigation('/doctor-profile/'+doctorId);
    setIsDropdownOpen(false);
  };

  
  const handleLogout = () => {
    karwaisko(); 
    setIsDropdownOpen(false);
  };

  if (!isAuthenticated) return null;

  const title = (authUser()?.doctorTitle) || "Dr. ";
  const firstName = (authUser()?.userFirstName || "");
  const lastName = (authUser()?.userLastName || "");
  const Name = ( firstName +  " " + lastName) || "User";
  const userName = (authUser()?.isDoctor ? title + " " + Name : "Mr. " + Name) || "User";

  return (
    
    <div className="position-relative" ref={dropdownRef}>
      <div 
        onClick={toggleDropdown} 
        className="d-flex align-items-center justify-content-center"
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: '#f0f0f0',
          cursor: 'pointer'
        }}
      >
        <User size={24} color="#666" />
      </div>
      
      {isDropdownOpen && (
        <div 
          className="position-absolute end-0 mt-2 bg-white shadow-sm rounded"
          style={{
            width: '300px',
            zIndex: 1000,
            border: '1px solid #e0e0e0'
          }}
        >
          <div className="p-3 border-bottom">
            <div className="d-flex align-items-center">
              <div 
                className="d-flex align-items-center justify-content-center me-3"
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  backgroundColor: '#e0e0e0'
                }}
              >
                <User size={30} color="#666" />
              </div>
              <div>
                <h6 className="mb-0" style={{color:'black'}} >{userName}</h6>
                <small className="text-muted">
                  
                  {ST.NAV_BAR_SECTION.LOGIN.label3}
                </small>
              </div>
            </div>
          </div>
          <div className="py-2">
            {
                authUser().isDoctor?
                <div onClick={handleProfileClick} className="d-flex align-items-center px-3 py-2 hover-bg-light" style={{ cursor: 'pointer', color:'black' }}>
                    <Settings size={18} className="me-2" />
                    {ST.NAV_BAR_SECTION.LOGIN.label2}
                </div>:<></>
            }
            
            <div 
              onClick={handleLogout}
              className="d-flex align-items-center px-3 py-2 hover-bg-light"
              style={{ cursor: 'pointer', color: '#f35b2a' }}
            >
              <LogOut size={18} className="me-2" />
              {ST.NAV_BAR_SECTION.LOGIN.label1 }
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Profiledropdown