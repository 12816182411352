import React, { useState } from 'react'
import './Contact.css'
import NavbarContact from './NavbarContact';
import Footer from './Footer';
import { useLanguage } from './LanguageContext';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    preferredDoctorId:'',
    preferredDoctorCenterId:'',
    preferredDoctorSpeciality:'',
    preferredClinicHospital:'',
    preferredTimeslot: '',
    preferredAvoidDays: '',
    isAptUrgent: '',
    insuranceType: '',
    insuranceNumber: '',
    specialMention: '',
    phone: '',
    email: '',
    terms_conditions: false,
    info:{
      message:''
    },
    type:'Contact Us'
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'message') {
      setFormData({
        ...formData,
        info: {
          ...formData.info,
          message: value,
        }
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleContact = async (e)=>{
    e.preventDefault();
    console.log('came into contact');
    const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+'/lead-capture', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      console.log('Form submitted successfully');
    } else {
      console.log('Failed to submit form');
    }
  }


  const { langStrings } = useLanguage();
  const ST = langStrings.CONTACTUS;

  return (
    <div>
    <NavbarContact />
    <div class="custom-containera">
    <div class="custom-form">
      <div class="custom-contact-info">
        <h3 class="custom-title">{ST.label}</h3>
        <p class="custom-text">
          {ST.label1}
        </p>
        <div class="custom-info">
          <div class="custom-information">
            <i class="fas fa-map-marker-alt"></i> 
            <div className='text-div'>Name</div>
          </div>
          <div class="custom-information">
            <i class="fas fa-envelope"></i> 
            <div className='text-div'>Email</div>
          </div>
          <div class="custom-information">
            <i class="fas fa-phone"></i> 
            <div className='text-div'>Phone</div>
          </div>
        </div>

        <div class="custom-social-media">
          <p>{ST.label2}</p>
          <div class="custom-social-icons">
            <a href="https://www.facebook.com/people/Arztme/61568272712289/?sk=about">
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="https://x.com/Arztme">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="https://www.instagram.com/arztme/profilecard/?igsh=azVleGhzeGpyanBr">
              <i class="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/arztme/?viewAsMember=true">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
      </div>

    <div class="custom-contact-form">
      <form style={{padding : "2.3rem 2.2rem"}} onSubmit={handleContact} autocomplete="off">
        <h3 class="custom-title">{ST.label3}</h3>
        <div class="custom-input-container">
          <input type="text" name="fullName" value={formData.fullName} class="custom-input" placeholder='Name' onChange={handleInputChange} />
        </div>
        <div class="custom-input-container">
          <input type="email" name="email" value={formData.email} class="custom-input" placeholder='Email' onChange={handleInputChange}/> 
        </div>
        <div class="custom-input-container">
          <input type="tel" name="phone" value={formData.phone} class="custom-input" placeholder={ST.label6} onChange={handleInputChange}/>
         
        </div>
        <div class="custom-input-container custom-textarea">
          <textarea name="message" value={formData.info.message} class="custom-input" placeholder={ST.label7} onChange={handleInputChange}></textarea>
        </div>
        <input type="submit" class="custom-btn" />
      </form>
    </div>
  </div>
</div> 
<Footer />
</div>
  )
}

export default ContactUs;