import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import './../css/UserLoginComponent.css';
import Constants from '../Constants';
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useSignIn, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { MDBInput } from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Footer from '../features_new/homepage/components/Footer';
import NavbarForgot from '../features_new/homepage/components/NavbarForgot';
import { useLanguage } from '../features_new/homepage/components/LanguageContext';
import { toast } from 'sonner';

export default function UserLoginComponent() {
  const [searchTextTop, setSearchTextTop] = useState("Please enter details for login");
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const togglePasswordVisibility = () => setShowPassword(prev => !prev);
  const navigate = useNavigate();
  const location = useLocation();
  const signIn = useSignIn();
  const userLoginUrl = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_LOGIN_REL_URL;
  
  const validateForm = () => {
    let valid = true;
    let errors = { email: '', password: '' };

    if (!email) {
      errors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email format is invalid";
      valid = false;
    }

    if (!password) {
      errors.password = "Password is required";
      valid = false;
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  async function handleFormSubmit(e) {
    // const cookieConsent = localStorage.getItem('cookieConsent');
    // if(cookieConsent == 'declined'){
    //   alert('You need to accept cookie for Logging In');
    //   navigate('/cookie-management');
    //   return ;
    // }
    e.preventDefault();
    if (!validateForm()) return;

    const formJson = { email, password };
    const reqUrl = reqUrlFromQuery(userLoginUrl, formJson);
    setSearchTextTop("Trying to login...");
    const resp = await callLoginHandler(reqUrl);

    if (resp.success) {
      setSearchTextTop("Hey, you are logged in successfully");
      if (signIn({
        token: resp.respJson.authToken,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState:{
        ...resp.respJson, 
        userId: resp.respJson.userDto.id, 
        isUser: resp.respJson.isUser,
        isDoctor: resp.respJson.isDoctor,
        doctorTitle: resp.respJson?.doctorDto?.title || "Dr. ",
        doctorId: resp.respJson?.doctorDto?.id || -1,
        userFirstName: resp.respJson.userDto.firstname,
        userLastName: resp.respJson.userDto.lastname  
        }
      })) {
        goToHomepage();
      } else {
        setSearchTextTop("Sign-in failed.");
      }
    } else {
      //setSearchTextTop("Login failed. Please check your email and password");
      toast.warning('Invalid Credentials', {
        position: 'top-center',
        duration: 3000,
        style: {
          fontSize: '15px', 
          padding: '10px 20px', 
          minWidth: '200px',
        },
      });
      //alert("Login failed. Please check your email and password.");
    }
  }

  const goToHomepage = () => {
    let redirectTo = location.state?.from?.pathname || '/';
    if (location?.state?.from === 'List Your Practice') {
      redirectTo = '/new-doctor-register';
    }
    navigate(redirectTo);
  };

  function reqUrlFromQuery(userLoginUrl, formJson) {
    return `${userLoginUrl}?email=${formJson.email}&password=${formJson.password}`;
  }

  async function callLoginHandler(url) {
    const resp = await fetch(url, {
      method: 'GET',
      headers: { 'Content-type': 'application/json; charset=UTF-8' }
    });
    const respJson = await resp.json();

    return resp.ok && respJson.isUser ? { success: true, respJson } : { success: false };
  }

  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = langStrings.LOGINPAGE;

  return (
    <div>
      <NavbarForgot />
      <section className="vh-100 bg-image user-register-view">
        <div className="mask d-flex align-items-center h-100 bg-light">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                <div className="card user-register-card">
                  <div className="card-body p-5">
                    <h2 className="text-center mb-5">{ST.label}</h2>
                    <div>
                      <form id="user-registration-form" onSubmit={handleFormSubmit} method="get">
                        <MDBInput
                          label={ST.label1}
                          name="email"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {errors.email && <p className="text-danger">{errors.email}</p>}
                        <br />
                        <div className="position-relative">
                          <MDBInput
                            label={ST.label2}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                            }}
                          >
                            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                          </span>
                        </div>
                        {errors.password && <p className="text-danger">{errors.password}</p>}
                        <br />
                        <div className="d-flex justify-content-center">
                          <button
                            style={{ backgroundColor: '#f35b2a', fontWeight: 'bold', color: 'white', boxShadow: 'none' }}
                            type="submit"
                            data-mdb-button-init
                            data-mdb-ripple-init
                            className="btn btn-success btn-block btn-lg"
                          >
                            {ST.label3}
                          </button>
                        </div>
                        <p className="text-center text-muted mt-5 mb-0">
                          {ST.label4} <a href="/new-user-register" className="fw-bold text-body">{ST.label5}</a>
                        </p>
                        <p className="text-center text-muted mb-0">
                          <a href="/forgot-password" className="fw-bold text-body">{ST.label6}</a>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
