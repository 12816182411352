import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSignOut, useIsAuthenticated, useAuthUser } from 'react-auth-kit';
import { useNavigate } from "react-router-dom";
import { useLanguage } from "./LanguageContext.js";
import HomePageView from "./HomePageView.jsx";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";

const Homepage = () => {
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { language, langStrings, toggleLanguage } = useLanguage();
  const ST = useMemo(() => langStrings.HOMEPAGE, [langStrings]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 960);
  const [detectedLocation, setDetectedLocation] = useState('Location');

  const handleNavigation = useCallback((path) => {
    console.log(path);
    navigate(path);
  }, [navigate]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth <= 960);
    }, 100);
    
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const karwaisko = useCallback(() => { 
    signOut();
    handleNavigation('/');
  }, [signOut, handleNavigation]);

  const detectLocation = () => {
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     async (position) => {
    //       const { latitude, longitude } = position.coords;
    //       const locationName = await getCity(latitude, longitude);
    //       setDetectedLocation(locationName);
    //     },
    //     (err) => {
    //       //toast.error("Unable to Find Location");
    //       console.error('Error detecting location:', err);
    //     }
    //   );
    // } else {
    //   //toast.error("Unable to Find Location");
    //   console.error("Geolocation is not supported by this browser.");
    // }
  };

  const getCity = async (latitude, longitude) => {
    const apiKey = process.env.REACT_APP_GEOCODING_API_KEY;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const cityComponent = addressComponents.find(component => component.types.includes("locality"))
        return cityComponent ? cityComponent.long_name : "Unknown location";
      } else {
        return "Unknown location";
      }
    } catch (error) {
      return "Unknown location";
    }
  };

  useEffect(() => {
   // detectLocation();
  }, []);
  console.log(detectedLocation)

  return (
    <HomePageView 
      authUser={authUser}
      handleNavigation={handleNavigation}
      isAuthenticated={isAuthenticated}
      isMobile={isMobile}
      language={language}
      langStrings={langStrings}
      ST={ST}
      toggleLanguage={toggleLanguage}
      karwaisko={karwaisko}
      isTablet={isTablet}
      detectedLocation={detectedLocation}
    />
  );
};

export default Homepage;
