import React, { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaCamera } from 'react-icons/fa'; 
import './ProfileHeader.css';

const ProfileHeader = ({ user }) => {
  const profileImagePrefix = process.env.REACT_APP_JAVA_BASE_URL + process.env.REACT_APP_USER_PROFILE_IMAGE_CONTENT_REL_PATH;
  const [profileImage, setProfileImage] = useState(user.prefixedProfileImage ? `${user.prefixedProfileImage}` : '/docSahab.jpg');
  const navigate = useNavigate();
  const handleImageChange = async (event) => {
    const File = event.target.files[0]; 
    if (File) {
      console.log(File);
      const imageUrl = URL.createObjectURL(File);
      setProfileImage(imageUrl);
      const formData = new FormData();
      formData.append('File', File);
      try {
        const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+`/doctor/uploadFile?doctorId=${user.id}`, {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          console.log('Image uploaded successfully');
        } else {
          console.error('Failed to upload image');
          setProfileImage(user.prefixedProfileImage ? `${user.prefixedProfileImage}` : '/docSahab.jpg');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        setProfileImage(user.prefixedProfileImage ? `${user.prefixedProfileImage}` : '/docSahab.jpg');
      }
    }
  };

  const takeToProfile = () => {
    navigate(`/doctor-profile/${user.id}`);
  };

  const [title, setTitle] = useState(user.tile? user.tile:'');
  const [first, setFirst] = useState(user.firstName ? user.firstName : '');
  const [last, setLast] = useState(user.lastname? user.lastname : '');
  const [list, setList] = useState(user.professionList? user.professionList.join(', ') : '');
  useEffect(() => {
    setTitle(user.title);
    setFirst(user.firstname);
    setLast(user.lastname);
    setList(user.professionList.join(', '));
  }, [user]);


  return (
    <div className="d-flex align-items-center p-3 bg-dark text-white">
      <div className="profile-image-container position-relative">
        <Image
          onClick={() => document.getElementById('profileImageInput').click()}
          src={profileImage}
          roundedCircle
          width="80"
          height="80"
          style={{ objectFit: 'cover', cursor: 'pointer' }} 
        />
        <div className="camera-icon-overlay">
          <FaCamera
            style={{ cursor: 'pointer' }}
            className="text-white"
            onClick={() => document.getElementById('profileImageInput').click()} 
          />
        </div>
        <input
          type="file"
          id="profileImageInput"
          accept="image/*"
          style={{ display: 'none' }} 
          onChange={handleImageChange} 
        />
      </div>
      <div className="ms-3">
        <h3 style={{ cursor: 'pointer' }} onClick={takeToProfile}>
          {`${title} ${first} ${last}`}
        </h3>
        <p className="mb-0">{`${list}`}</p>
      </div>
    </div>
  );
};

export default ProfileHeader;
