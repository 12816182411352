import React, { useState, useRef, useCallback } from 'react';
import { PlusIcon, TrashIcon, GripVertical } from 'lucide-react';

const BackgroundImageUploader = () => {
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const fileInputRef = useRef(null);
  const MAX_IMAGES = 6;

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(file => {
      const isImage = file.type.startsWith('image/');
      const isValidSize = file.size / 1024 / 1024 < 2;
      
      if (!isImage) {
        alert(`${file.name} is not an image file`);
      }
      if (!isValidSize) {
        alert('Image must be smaller than 2MB');
      }
      return isImage && isValidSize;
    });

    const newTotalImages = backgroundImages.length + validFiles.length;
    if (newTotalImages > MAX_IMAGES) {
      alert(`You can only upload up to ${MAX_IMAGES} background images`);
      return;
    }

    const newImageFiles = validFiles.map(file => ({
      id: `image-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      file,
      url: URL.createObjectURL(file)
    }));

    setBackgroundImages(prev => [...prev, ...newImageFiles]);
  };

  const removeImage = (idToRemove) => {
    const updatedImages = backgroundImages.filter(image => image.id !== idToRemove);
    setBackgroundImages(updatedImages);
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  const handleDragStart = (e, index) => {
    setDraggedItem(backgroundImages[index]);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    e.stopPropagation();

    if (draggedItem) {
      const newImages = [...backgroundImages];
      const draggedIndex = newImages.findIndex(img => img.id === draggedItem.id);
      newImages.splice(draggedIndex, 1);
      newImages.splice(targetIndex, 0, draggedItem);

      setBackgroundImages(newImages);
      setDraggedItem(null);
    }
  };

  return (
    <div className="bg-white rounded-3 shadow-sm p-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h5 className="mb-0">Background Images</h5>
        <span className="text-muted">
          {backgroundImages.length} / {MAX_IMAGES}
        </span>
      </div>
      <input 
        type="file" 
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        multiple
        className="d-none"
      />

      <div className="row g-3">
        {backgroundImages.map((image, index) => (
          <div 
            key={image.id} 
            className="col-4 position-relative"
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
          >
            <div className="position-relative">
              <div 
                className="position-absolute top-2 start-2 z-10 text-muted cursor-move"
              >
                <GripVertical size={20} />
              </div>
              <img 
                src={image.url} 
                alt={`Background ${index + 1}`}
                className="img-fluid rounded-3 object-fit-cover"
                style={{ 
                  height: '200px', 
                  width: '100%' 
                }}
              />
              <button
                onClick={() => removeImage(image.id)}
                className="btn btn-danger btn-sm position-absolute top-2 end-2 rounded-circle d-flex align-items-center justify-content-center"
                style={{ 
                  width: '30px', 
                  height: '30px', 
                  padding: 0 
                }}
              >
                <TrashIcon size={16} />
              </button>
            </div>
          </div>
        ))}
        
        {backgroundImages.length < MAX_IMAGES && (
          <div className="col-4">
            <button
              onClick={triggerFileInput}
              className="btn btn-outline-secondary w-100 h-100 d-flex flex-column align-items-center justify-content-center rounded-3 border-dashed"
              style={{ 
                minHeight: '200px',
                borderWidth: '2px',
                borderStyle: 'dashed'
              }}
            >
              <PlusIcon size={32} className="text-muted mb-2" />
              <span className="text-muted">Upload Image</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BackgroundImageUploader;