import React, { useEffect, useRef, useState } from 'react';
import './MobileImageCard.css'; 
import { ImLocation2 } from "react-icons/im";
import { FaEuroSign } from "react-icons/fa";
import { BsGlobe2 } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { MdPhoneInTalk } from "react-icons/md";
import { SlSpeech } from "react-icons/sl";
import { MdStarRate } from "react-icons/md";
import { MdStarPurple500 } from "react-icons/md";
import { FaCalendarAlt } from "react-icons/fa";
import { useLanguage } from '../../homepage/components/LanguageContext';
import { Button, Modal } from 'react-bootstrap';
import { Form,Row,Col } from 'react-bootstrap';
import { RiFirstAidKitFill } from "react-icons/ri";
import { IoMdStar } from "react-icons/io";
import ScrollComponent from './ScrollComponent';
import emailjs from 'emailjs-com'
import { useNavigate } from 'react-router-dom';
import { GiTwirlyFlower } from "react-icons/gi";
const allDays = [0,1, 2, 3, 4, 5];
const dayNames = ["Mon,", "Tue,", "Wed,", "Thu,", "Fri,", "Sat,","Sun,"];
const monthNames = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]



const convertToTime = (minutes) => {
  if (minutes === null) return null;
  
  const hours = Math.floor(minutes / 60);
  let mins = minutes % 60;

  mins = mins < 10 ? '0' + mins : mins;
  
  return `${hours}:${mins}`;
};

const MobileImageCard = (
    { user,
      isdoc,
        searchTextTop,
        getDocCountMatchingStr,
        profileImagePrefix,
        count,
        users,
        unsetAllDocs,
        setSelectedDoc,
        getDocLocAddressStr,
        selectedDoc,
        searchFormData,
        handleFormChange,
        handleFormSubmitNew,
        setSearchFormData }
) => {
  const form = useRef();
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const sendEmail = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_USER_ID)
      .then((result) => {
        console.log('Email sent successfully:', result.text);
      }, (error) => {
        console.error('Failed to send email:', error);
      });
  };

  const { language,langStrings } = useLanguage();
  const ST = langStrings.SEARCHFIL;
  const MT = langStrings.BOOKINGMODAL;
  const dayWiseSlots = user?.aptSlotsApi?.dayWiseSlots || [];
  const dateWiseSlots = user?.aptSlotsApi?.datewiseSlots || [];
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dayIs = (daySlot) =>{
    const [year, month, day] = daySlot.split('-');
    const dayNumber = parseInt(day, 10);
    return dayNumber;
  }
  const monthIs = (daySlot) =>{
    const [year, month, day] = daySlot.split('-');
    return month;
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  const navigate = useNavigate();
  const docLocation = getDocLocAddressStr(user.centers[0]);
  const docLocationMap = {lat:user?.centers?.[0]?.lat || 47.80164,lng:user?.centers?.[0]?.lng||47.80164};

  
  const [formData, setFormData] = useState({
    fullName: '',
    preferredDoctorId:user.id,
    preferredDoctorCenterId:user?.aptSlotsApi?.doctorCenterId || -1,
    preferredDoctorSpeciality:'',
    preferredClinicHospital:'',
    preferredTimeslot: '',
    preferredAvoidDays: '',
    isAptUrgent: '',
    insuranceType: '',
    insuranceNumber: '',
    specialMention: '',
    phone: '',
    email: '',
    terms_conditions: false,
    info:{

    },
    type:'Booking Request',
  });
  const [errors, setErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = MT.label26;
    if (!formData.preferredTimeslot) newErrors.preferredTimeslot = MT.label27;
    if (!formData.preferredAvoidDays) newErrors.preferredAvoidDays = MT.label28;
    if (!formData.isAptUrgent) newErrors.isAptUrgent = MT.label29;
    if (!formData.insuranceType) newErrors.insuranceType = MT.label30;
    if (formData.insuranceType && !formData.insuranceNumber) newErrors.insuranceNumber = MT.label31;
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) newErrors.phone = MT.label32;
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = MT.label33;
    if (!formData.terms_conditions) newErrors.terms_conditions = MT.label34;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit1 = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit(e);
      handleClose();
    }else{
      alert('Please Fill all the Fields Correctly');
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(process.env.REACT_APP_JAVA_BASE_URL+'/lead-capture', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      console.log('Form submitted successfully');
    } else {
      console.log('Failed to submit form');
    }
  };


  return (
    <div style={{margin:'15px 0px'}} class="my-cards" >
    
    <div >
    {/* onMouseEnter={(e) => { unsetAllDocs(); user.isSelected = true; setSelectedDoc(user.id)}}; */}

      {/* photo and name */}
      <div onClick={() =>{  navigate(`/doctor-profile/${user.id}`, { state: { user,docLocation,docLocationMap,isdoc} })}}  style={{display:'flex',marginLeft:'12px',marginTop:'12px'}}>
        <img  src={user.prefixedProfileImage ? `${user.prefixedProfileImage}` : '/docSahab.jpg'} alt="" style={{borderRadius:'999px',height:'80px',width:'80px'}} />
        <div style={{display:'flex',flexDirection:'column',fontWeight:'bold',paddingLeft:'20px',marginTop:'12px'}}>
          <p style={{color:'#f35b2a'}}>
            {user.title + ' ' + user.firstname + ' ' + user.lastname}
          </p>
          <p>
            {user.professionList == 'Doctor' ?  user.specialityList.join(', '):user.professionList.join(', ')}
          </p>
        </div>
      </div>


      <div>

          <div style={{display:'flex',alignItems:'center',padding:'8px',margin:'0px'}} >
            <ImLocation2 style={{marginRight:'8px',width:"20px",height:"20px"}} />
            {user.centers && user.centers.length ? <span class="my-0 font-weight-normal">  {getDocLocAddressStr(user.centers[0])} </span> : <span></span>}
          </div>
       
          <div style={{display:'flex',alignItems:'center',padding:'8px',margin:'0px'}} >
            <FaEuroSign style={{marginRight:'8px',width:"20px",height:"20px"}} />
            <span>Gesetzlich und privat Versichert sowie Selbstzahlende</span>
          </div>

          <div style={{display:'flex',alignItems:'center',padding:'8px',margin:'0px'}}>
            <BsGlobe2  style={{marginRight:'8px',width:"20px",height:"20px"}} />
            <span>www.abc.at</span>
          </div>

          <div style={{display:'flex',alignItems:'center',padding:'8px',margin:'0px'}}>
            <SlSpeech style={{marginRight:'8px',width:"20px",height:"20px"}} />
            {user.centers && user.centers.length ? <span class="my-0 font-weight-normal">  {user.centers[0].mail} </span> : <span></span>}
          </div>

          <div style={{display:'flex',alignItems:'center',padding:'8px',margin:'0px'}} >
            <MdPhoneInTalk style={{marginRight:'8px',width:"20px",height:"20px"}} />
            {user.centers && user.centers.length ? <span class="my-0 font-weight-normal">  {user.centers[0].phone} </span> : <span></span>}
          </div>
          
          <div style={{display:'flex',alignItems:'center',padding:'8px',margin:'0px'}} >
          <IoMdStar style={{marginRight:'8px',width:"20px",height:"20px"}} />
          <span>(4.2) {ST.IMAGECARD.label3}</span>
          </div>
          <div onClick={handleShow1} style={{display:'flex',alignItems:'center',padding:'8px',margin:'0px',cursor:'pointer'}} >
          <GiTwirlyFlower style={{marginRight:'5px'}}/>
          <span>Arztme Stars</span>
          </div>
    
           
           

      </div>
    </div>

      
      
        <div style={{display:'flex'}}>

        
        
          <div  className='bhenChod'> 
            <div style={{color:'#ffd900',fontSize:'25px',fontWeight:'bold',height:'25%'}}>
            {user.info == null || Object.keys(user.info).length === 0 ? 'NA' : language == 'English'? user.info['1_a_n_p'] : user.info['1_a_n_p'] == 'Yes' ? 'Ja' : 'Nein'}
            </div>
            <div style={{fontSize:'18px',height:'75%',color:'white',paddingTop:'10px',lineHeight:'1.25'}}>
              {ST.IMAGECARD.label}
            </div>
          </div>
       


       <div className='bhenChod' >
          <div style={{color:'#ffd900',fontSize:'25px',fontWeight:'bold',height:'25%'}}>
          {user.info == null || Object.keys(user.info).length === 0 ?'NA':user.info['2_a_w_t']} {ST.IMAGECARD.label12}
          </div>
          <div style={{fontSize:'18px',height:'75%',color:'white',paddingTop:'10px',lineHeight:'1.25'}}>
          {ST.IMAGECARD.label1}
          </div>
        </div>
        </div>

      
       
          <div style={{borderRadius:'20px',margin:'5px'}}>
            <div onClick={handleShow} style={{height:'15%',backgroundColor:'#f35b2a',fontWeight:'bold',color:'white',display:'flex', justifyContent:'center',alignItems:'center',fontSize:'18px',borderRadius: '20px',cursor:'pointer',border:'solid'}}>
              <FaCalendarAlt style={{marginRight:'5px'}} />
              <span >{ST.IMAGECARD.label10}</span>
            </div>
          <div style={{height:'85%',marginTop:'5px', border:'1px solid #a0a0a0', borderRadius:'20px',overflow:'clip',boxSizing: 'border-box'}}>
          <div style={{height:'15%',backgroundColor:'#a0a0a0',color:'white',fontWeight:'bold',fontSize:'15px',justifyContent:'center',alignItems:'center',display:'flex', borderRadius:'12px 12px 0px 0px'}}>
            <span className='Bookings-text1'>{ST.IMAGECARD.label11}</span>
          </div>
        <div>
          {
            allDays.map((day,index)=>{
              
              const slot = dayWiseSlots.find(slot => slot.dow === index+1); 
             
              const beforeLunch = slot && slot.beforeLunchSt ? `${convertToTime(slot.beforeLunchSt)} - ${convertToTime(slot.beforeLunchEn)}` : '00:00 - 00:00';
              const afterLunch = slot && slot.afterLunchSt ? `${convertToTime(slot.afterLunchSt)} - ${convertToTime(slot.afterLunchEn)}` : '00:00 - 00:00';
              return(
              <div className='timings' key={index} >
                <div style={{fontSize:'13px'}} className='Bookings-label' >
                  {ST.IMAGECARD[`label${index+4}`]}
                </div>
                { beforeLunch != '00:00 - 00:00' ?
                  <button className='Bookings-btn' >
                    {beforeLunch}
                  </button>:
                  <button className='Bookings-btn1' >
                    {beforeLunch}
                  </button>
                }
                
                {afterLunch != '00:00 - 00:00'?
                  <button className='Bookings-btn' >
                    {afterLunch}
                  </button>:
                  <button className='Bookings-btn1' >
                    {afterLunch}
                  </button>
                }
              </div>);
            })
          }
        </div>
    </div>
  </div>
      
      
   
    
  <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{MT.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bg-dark text-white">
          <div style={{ backgroundColor: '#f35b2a' }} className="p-4">
            <h5 style={{ textAlign: 'justify', textJustify: 'inter-word' }} className="mb-4">
              {MT.label1}
            </h5>
            <p style={{ textAlign: 'justify', textJustify: 'inter-word' }} className="text-white h5 font-weight-bold mb-4">
              {MT.label2}
            </p>
          </div>
          <div className="">
            <Form onSubmit={handleSubmit1}>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formName">
                    <Form.Label style={{ color: 'white' }}>{MT.label3}</Form.Label>
                    <Form.Control
                      type="text"
                      name="fullName"
                      placeholder={MT.label24}
                      value={formData.fullName}
                      onChange={handleInputChange}
                    />
                    {errors.fullName && <div className="text-danger">{errors.fullName}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Label style={{ color: 'white' }}>{MT.label4}</Form.Label>
                  <div className="d-flex justify-content-around">
                    <Form.Check
                      type="radio"
                      label={MT.label14}
                      name="preferredTimeslot"
                      value="Morning"
                      onChange={handleInputChange}
                      checked={formData.preferredTimeslot === 'Morning'}
                    />
                    <Form.Check
                      type="radio"
                      label={MT.label15}
                      name="preferredTimeslot"
                      value="Afternoon"
                      onChange={handleInputChange}
                      checked={formData.preferredTimeslot === 'Afternoon'}
                    />
                    <Form.Check
                      type="radio"
                      label={MT.label16}
                      name="preferredTimeslot"
                      value="Evening"
                      onChange={handleInputChange}
                      checked={formData.preferredTimeslot === 'Evening'}
                    />
                  </div>
                  {errors.preferredTimeslot && <div className="text-danger">{errors.preferredTimeslot}</div>}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formSpecificTimes">
                    <Form.Label style={{ color: 'white' }}>{MT.label5}</Form.Label>
                    <Form.Control
                      type="text"
                      name="preferredAvoidDays"
                      placeholder={MT.label25}
                      value={formData.preferredAvoidDays}
                      onChange={handleInputChange}
                    />
                    {errors.preferredAvoidDays && <div className="text-danger">{errors.preferredAvoidDays}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Label style={{ color: 'white' }}>{MT.label6}</Form.Label>
                  <div className="d-flex justify-content-around">
                    <Form.Check
                      type="radio"
                      label={MT.label17}
                      name="isAptUrgent"
                      value="Yes"
                      onChange={handleInputChange}
                      checked={formData.isAptUrgent === 'Yes'}
                    />
                    <Form.Check
                      type="radio"
                      label={MT.label18}
                      name="isAptUrgent"
                      value="No"
                      onChange={handleInputChange}
                      checked={formData.isAptUrgent === 'No'}
                    />
                  </div>
                  {errors.isAptUrgent && <div className="text-danger">{errors.isAptUrgent}</div>}
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Label style={{ color: 'white' }}>{MT.label7}</Form.Label>
                  <div className="d-flex justify-content-around">
                    <Form.Check
                      type="radio"
                      label={MT.label19}
                      name="insuranceType"
                      value="Public"
                      onChange={handleInputChange}
                      checked={formData.insuranceType === 'Public'}
                    />
                    <Form.Check
                      type="radio"
                      label={MT.label20}
                      name="insuranceType"
                      value="Private"
                      onChange={handleInputChange}
                      checked={formData.insuranceType === 'Private'}
                    />
                  </div>
                  {errors.insuranceType && <div className="text-danger">{errors.insuranceType}</div>}
                  <Form.Group controlId="formInsuranceNumber" className="mt-3">
                    <Form.Label style={{ color: 'white' }}>{MT.label8}</Form.Label>
                    <Form.Control
                      type="text"
                      name="insuranceNumber"
                      placeholder={MT.label21}
                      value={formData.insuranceNumber}
                      onChange={handleInputChange}
                    />
                    {errors.insuranceNumber && <div className="text-danger">{errors.insuranceNumber}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formNotes">
                    <Form.Label style={{ color: 'white' }}>{MT.label9}</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="specialMention"
                      rows={3}
                      value={formData.specialMention}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="formPhone">
                    <Form.Label style={{ color: 'white' }}>{MT.label10}</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder={MT.label22}
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                    {errors.phone && <div className="text-danger">{errors.phone}</div>}
                  </Form.Group>
                  <Form.Group controlId="formEmail" className="mt-3">
                    <Form.Label style={{ color: 'white' }}>{MT.label11}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={MT.label23}
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && <div className="text-danger">{errors.email}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="formTerms" className="mt-3">
                <Form.Check
                  type="checkbox"
                  name="terms_conditions"
                  label={MT.label12}
                  checked={formData.terms_conditions}
                  onChange={handleInputChange}
                />
                {errors.terms_conditions && <div className="text-danger">{errors.terms_conditions}</div>}
              </Form.Group>

              <Button style={{ backgroundColor: '#f35b2a' }} type="submit" className="w-100 mt-3">
                {MT.label13}
              </Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  

<Modal show={show1} onHide={handleClose1} size="lg" centered>
        <Modal.Body>
          <div className="modal-content-structure">
            <div className="top-section">
              <div className="logo">
                <img src="./artzme-star1.png" className="flower-icon" alt="Arztme logo" />
              </div>
              <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <h2 className="title">Arztme <span className="star-text">Star</span></h2>
                <p className="subtitle">{ST.STARMODAL.label}</p>
              </div>

            </div>

          
            <div className="bottom-section">
              <div className="item">
              <img src="./artzme-star1.png" className="flower-icon1" alt="Arztme logo" />
              
                <p className='h3'>{ST.STARMODAL.label1}</p>
              </div>
              <div className="item">
              <img src="./artzme-star1.png" className="flower-icon1" alt="Arztme logo" />
              <img src="./artzme-star1.png" className="flower-icon1" alt="Arztme logo" />
                <p className='h3'>{ST.STARMODAL.label2}</p>
              </div>
              <div className="item">
              <img src="./artzme-star1.png" className="flower-icon1" alt="Arztme logo" />
              <img src="./artzme-star1.png" className="flower-icon1" alt="Arztme logo" />
              <img src="./artzme-star1.png" className="flower-icon1" alt="Arztme logo" />
                <p className='h3'>{ST.STARMODAL.label3}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
<style jsx>{
  `
  @media (max-width:1920px){
    .my-cards{
      display:flex;
      color:black;
      height:auto;
      margin-top:20px;
      margin-bottom:20px;
      margin-left:155px;
      width:85%;
      background-color:#f2f2f2;
      border-radius:20px;
    }
    .bhenChod{
      width:100%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
      margin:10px;
      background-color:#a0a0a0;
      border-radius:20px;
    }
    .bhenChod-header{
      color:#ffd900;
      font-size:25px;
      font-weight:bold;
      height:25%;
    }
    .bhenChod-footer{
      font-size:18px;
      height:75%;
      color:white;
      padding-top:10px;
      line-height:1.25;
    }
    .Bookings{
      width:34%;
      margin:10px;
      border-style:solid;
      border-color:grey;
      border-width:2px;
      border-radius:22px;
    }
    .Bookings-text{
      margin-right:48px;
      cursor: pointer;
    }
    .Bookings-text1{
      margin-left:0px;
    }
    .Bookings-btn{
      width:33%;
      border-width:0px;
      background-color:#a0a0a0;
      color:white;
      border-radius: 20px;
    }
    .Bookings-btn1{
      width:33%;
      border-width:0px;
      background-color:#f2f2f2;
      color:#f2f2f2;
      border-radius: 20px;
    }
    .Bookings-label{
      width:33%;
      color:#7f7f7f;
      
    }
    .timings{
      height:14%;
      margin:8px 10px;
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
  }

  @media (max-width:1440px){
    .my-cards{
      display:flex;
      color:black;
      height:100%;
      margin-top:20px;
      margin-bottom:20px;
      margin-left:60px;
  
      width:95%;
      background-color:#f2f2f2;
      border-radius:20px;
    }
    .bhenChod{
      width:50%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
      margin:10px;
      background-color:#a0a0a0;
      border-radius:20px;
    }
    .bhenChod-header{
      color:#ffd900;
      font-size:25px;
      font-weight:bold;
      height:25%;
    }
    .bhenChod-footer{
      font-size:18px;
      height:75%;
      color:white;
      padding-top:10px;
      line-height:1.25;
    }
    .Bookings{
      width:34%;
      margin:10px;
      border-style:solid;
      border-color:grey;
      border-width:2px;
      border-radius:22px;
    }
    .Bookings-text{
      margin-right:48px;
      cursor: pointer;
    }
    .Bookings-text1{
      margin-left:0px;
    }
    .Bookings-btn{
      width:33%;
      border-width:0px;
      background-color:#a0a0a0;
      color:white;
      border-radius: 20px;
    }
    .Bookings-btn1{
      width:33%;
      border-width:0px;
      background-color:#f2f2f2;
      color:#f2f2f2;
      border-radius: 20px;
    }
    .Bookings-label{
      width:33%;
      color:#7f7f7f;
      
    }
    .timings{
      height:14%;
      margin:8px 10px;
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
  }
  @media (max-width:1024px){
    .my-cards{
      display:flex;
      color:black;
      height:70%;
      margin-top:20px;
      margin-bottom:20px;
      margin-left:15px;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
    }

    .bhenChod{
      width:50%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      text-align:center;
      margin:10px 5px 10px;
      background-color:#a0a0a0;
      border-radius:20px;
      
    }
    .bhenChod-header{
      color:#ffd900;
      font-size:25px;
      font-weight:bold;
      height:25%;
    }
    .bhenChod-footer{
      font-size:18px;
      height:75%;
      color:white;
      padding-top:10px;
      line-height:1.25;
    }
    .Bookings{
      width:34%;
      margin:10px 0px 10px;
      border-style:solid;
      border-color:grey;
      border-width:2px;
      border-radius:22px;
    }
    .Bookings-text{
      margin-right:20px;
      cursor: pointer;
    }
    .Bookings-text1{
      margin-left:30px;
    }
    .Bookings-btn{
      width:33%;
      border-width:0px;
      background-color:#a0a0a0;
      color:white;
      border-radius: 20px;
      font-size: 11px;
    }
    .Bookings-btn1{
      width:33%;
      border-width:0px;
      background-color:#f2f2f2;
      color:#f2f2f2;
      border-radius: 20px;
      font-size: 11px;
    }
    .Bookings-label{
      width:33%;
      color:#7f7f7f;
      font-size: 15px;
    }
    .timings{
      height:14%;
      margin:18px 10px;
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
  }
  @media (max-width:600px){
    .my-cards{
      display:flex;
      color:black;
      height:70%;
      margin-top:20px;
      margin-bottom:30px;
      margin-left:15px;
      flex-direction:column;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
    }
    .timings{
      margin:3px 10px;
    }
  }
    @media (max-width:420px){
    .my-cards{
      display:flex;
      color:black;
      height:70%;
      margin-top:20px;
      margin-bottom:30px;
      margin-left:15px;
      flex-direction:column;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
    }
    .timings{
      margin:3px 10px;
    }
  }
  @media (max-width:375px){
    .my-cards{
      display:flex;
      color:black;
      height:70%;
      margin-top:20px;
      margin-bottom:30px;
      margin-left:15px;
      flex-direction:column;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
    }
    .timings{
      margin:3px 10px;
    }
  }
 
  @media (max-width:320px){
    .my-cards{
      display:flex;
      color:black;
      height:70%;
      margin-top:20px;
      margin-bottom:30px;
      margin-left:15px;
      flex-direction:column;
      width:100%;
      background-color:#f2f2f2;
      border-radius:20px;
    }
    .timings{
      margin:3px 0px;
    }
  }

  `
}
</style>
  </div>
  )
}

export default MobileImageCard